
























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    // InsBanner: () => import('@/components/base/InsBanner.vue'),
    InsCatLayout1: () => import('@/components/business/cms/InsCatLayout1.vue'),
    InsCatLayout2: () => import('@/components/business/cms/InsCatLayout2.vue'),
    inPage: () => import('@/components/base/InsPage.vue')
  }
})
export default class insNews extends Vue {
  cmsCategory: object = {}; // cms下单个目录的信息
  cmsCatTree: object[] = []; // cms目录
  contentList: object[] = []; // cms内容列表
  catId: number = 0; // Tree点击获取内容列表的目录id
  PageStyle: string = '0'; // catDetail页面类型
  private CurrentPage: number = 1;
  private pageSize: number = 4;
  TotalRecord: number = 0; // 总条目数
  private waiting: boolean = true;
  private catclass: boolean = false;
  showPageSize: number | null = 0; // 40120
  TctshowPageSize: number = 4; // 40109
  showTotalRecord: number = 0; // 40120
  NonameinPage = false ;
  private SortOrder: string = 'desc';
  private SortName: string = 'CreateDate'

  // 根据设备类型获取CMSCategory信息
  getCategoryByDevice () {
    this.$Api.cms
      .getCategoryByDevice({ CatId: this.id, IsMobile: this.isMobile })
      .then(result => {
        // console.log(result, 'catDetail');
        this.cmsCategory = result;
        this.PageStyle = result.PageStyle;
        if (result.PageStyle === '2') {
          this.getCategoryTree();
        } else if (result.PageStyle === '3') {
          this.getSubCatContents();
        }

        this.$nextTick(() => {
          if (result.Name) document.title = result.Name;
          (document.getElementsByName('keywords')[0] as any).content =
            result.SeoKeyword;
          (document.getElementsByName('description')[0] as any).content =
            result.SeoDesc;
          (document.getElementsByName(
            'twitter:description'
          )[0] as any).content = result.SeoDesc;
          (document.getElementsByName('twitter:title')[0] as any).content =
            result.Name;
        });
      })
      .catch(error => {
        console.log(error, 'error');
        this.$message({
          message: error,
          type: 'error'
        });
      });
  }

  // 获取cms该id下所有的Category
  getCategoryTree () {
    this.$Api.cms
      .getCategoryTree({ id: this.id, IsMobile: this.isMobile })
      .then(result => {
        if (result && result.length) {
          this.cmsCatTree = result;
          this.catId = result[0].Id;
          this.getFromContentByCatId();
        } else {
          this.getFromContentByCatId();
        }
      });
  }

  // 获取cms该id下所有的cms
  getFromContentByCatId () {
    let catId = this.catId || this.id;
    if (this.$route.path === '/cms/catDetail/40120' || this.$route.path === '/cms/catDetail/40121') {
      this.pageSize = 8;
    } else if (this.$route.path === '/cms/catDetail/40109' || this.$route.path === '/cms/catDetail/40123') {
      this.pageSize = 6;
      // this.SortName = 'CreateDate';
    } else if (this.$route.path === '/cms/catDetail/40123' || this.$route.path === '/cms/catDetail/40124') {
      this.SortName = 'CreateDate';
      this.SortOrder = 'asc';
    } else if (this.$route.path === '/cms/catDetail/40122') {
      this.SortName = 'CreateDate';
      this.SortOrder = 'desc';
    } else {
      this.pageSize = 4;
    }
    this.$Api.cms
      .getFromContentByCatId(catId, this.CurrentPage, this.pageSize, this.isMobile, this.SortName, this.SortOrder)
      .then(result => {
        // console.log(this.CurrentPage, 'this.CurrentPage');
        // console.log(this.pageSize, 'this.pageSize');
        // console.log(this.TotalRecord, 'this.TotalRecord');
        // console.log(result.Data, 'getFromContentByCatId');
        this.contentList = result.Data;
        this.TotalRecord = result.TotalRecord;
        this.waiting = false;
        // if (this.$route.path === '/cms/catDetail/40123') {
        //   result.Data.sort(function (a, b) {
        //     return a.Id > b.Id ? 1 : -1;
        //   });
        // }
        if (this.$route.path === '/cms/catDetail/40120' || this.$route.path === '/cms/catDetail/40121') {
          this.catclass = true;
          this.showPageSize = 8;
        } else if (this.$route.path === '/cms/catDetail/40123') {
          this.showPageSize = 6;
          this.SortName = 'CreateDate';
          this.SortOrder = 'asc';
        } else if (this.$route.path === '/cms/catDetail/40122') {
          this.SortName = 'CreateDate';
          this.SortOrder = 'desc';
        } else {
          this.showPageSize = 4;
        }
        document.documentElement.scrollTop = 0;
      });
  }

  // 获取 Category 和所有 SubCategory 的 cms 列表
  getSubCatContents () {
    this.$Api.cms
      .getSubCatContents({ CatId: this.id, IsMobile: this.isMobile })
      .then(result => {
        console.log(result, ' 获取 Category 和所有 SubCategory 的 cms 列表');
        this.contentList = result.Data;
      });
  }

  // 树形控件选择的cms目录改变
  changeCatSelect (Id) {
    this.CurrentPage = 1;
    this.catId = Id;
    this.getFromContentByCatId();
  }

  get id () {
    return this.$route.params.id;
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  @Watch('id', { deep: true })
  onKeyChange () {
    this.cmsCategory = {};
    this.cmsCatTree = [];
    this.contentList = [];
    this.catId = 0;
    this.getCategoryByDevice();
  }

  @Watch('isMobile', { deep: true })
  onMediaChange () {
    if (this.PageStyle === '0') {
      this.cmsCategory = {};
      this.cmsCatTree = [];
      this.contentList = [];
      this.catId = 0;
      this.getCategoryByDevice();
    }
  }
  @Watch('CurrentPage')
  onPageChange (o, n) {
    // alert(this.CurrentPage);
    this.getFromContentByCatId();
  }

  @Watch('$route', { deep: true })
  onRouteChange (n, o) {
    console.log(this.$route.path, 'this.$route.path-Watch555');
    if (this.$route.path === '/cms/catDetail/40123') {
      this.NonameinPage = true;
      console.log(777);
    } else {
      this.NonameinPage = false;
      console.log(888);
    }
    if (this.$route.path === '/cms/catDetail/40123') {
      this.SortName = 'CreateDate';
      this.SortOrder = 'asc';
    }
  }

  mounted () {
    this.getCategoryByDevice();
    console.log(this.$route.path, 'this.$route.path-mounted');
    if (this.$route.path === '/cms/catDetail/40123') {
      this.NonameinPage = true;
      console.log(666);
      this.SortName = 'CreateDate';
      this.SortOrder = 'asc';
    }
  }
}
